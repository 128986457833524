<template>
	<div class="jonatan-content">
		<h2>Hola mundo!!!</h2>
		<phi-input label="prueba input regex" v-model="inputPrueba" @keydown="testRegExr($event)" ></phi-input>
		
	</div>
</template>

<script>
/*
import PhiStoreHeader from '@/components/Phi/Store/Header.vue';
import PhiStoreRecharge from '@/components/Phi/Store/Recharge.vue';
import PhiStoreTransaction from '@/components/Phi/Store/Transaction.vue';
*/

import app from '@/store/app.js';
import PhiInput from '../components/Phi/Input.vue';
//import PhiImageToolChangeAvatar from '@/modules/profile/components/changeavatar/Changeavatar.vue';
//import QuieroMiFactura from '@/modules/v3/components/V3QuieromifacturaLog/V3QuieromifacturaLog.vue';
//import DianLog from '@/modules/v3/components/V3DianLog/V3DianLog.vue';

export default {
	components: {
		PhiInput
	},

	data() {
		return {
			app,
			inputPrueba : null,
		};
	},

	methods: {
		testRegExr(event) {
				// event.preventDefault();
			// console.log(event.keyCode,event)
			// return
			let pattern = /^[A-Za-z0-9 \s]+$/g;
			console.table({
				"pattern": "pattern",
				"value" : event.target.value,
				"eval" : pattern.test(event.key),
				"key" : event.key
			})
			if(!/^[A-Za-z0-9 \s]+$/g.test(event.key)){
				event.preventDefault();
				console.log("pass",pattern.test(event.key))
			}

			// this.inputPrueba = event.replace(pattern,"")
			// console.log('valor de input: ', event);
			// return false;
		},
	},
};
</script>

<style lang="scss">
	.jonatan-content{
		width: 100%;
		height: 100%;
		padding: 20px 20px 20px 20px;

		h2{
			padding: 30px;
		}
	}
</style>